<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <h2 class="text-2xl font-bold">Input multi email</h2>
      <asom-input-multi-email class="w-1/2" v-model="input1" />
      <p>Email list: {{input1}}</p>
      <h3 class="text-xl font-bold">Input State</h3>
      <div class="space-y-4 pb-10 w-1/2 text-left">
        <asom-input-multi-email v-for="(s, i) in states"
          :key="i"
          :state="s"
        />
      </div>
    </div>
  </docs-layout>
</template>

<script>
import DocsLayout from '@/docs/DocsLayout.vue'
import AsomInputMultiEmail from '../../../components/input/multi_email/AsomInputMultiEmail.vue';

export default {
  components: {
    DocsLayout,
    AsomInputMultiEmail,
  },
  data() {
    return {
      input1: "",
      states: [ 'default', 'error' ]
    };
  }
}
</script>

<style>

</style>