<template>
  <asom-container class="pt-10 px-10 flex flex-col justify-start items-start">
    <div class="mb-4">
      <router-link v-if="showBackLink" to="/docs" v-slot="{ href, navigate}">
        <asom-link :href="href" @click="navigate">Back to Docs</asom-link>
      </router-link>
    </div>
    <slot></slot>
  </asom-container>
</template>
<script>
import AsomContainer from '../components/container/AsomContainer'
import AsomLink from '../components/link/AsomLink.vue'

export default {
  components: { AsomLink, AsomContainer },
  props: {
    showBackLink: {
      type: Boolean,
      default: true
    }
  }
}
</script>